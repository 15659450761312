import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs'
import Section from '../components/section/section'
import SectionHeadline from '../components/section/sectionHeadline'
import FilterBar from '../components/filterBar/filterBar'
import filterMethods from '../utils/methodsFilter'
import { capitalize } from '../utils/text'
import ToolsListing from '../components/toolsListing/toolsListing'
import mapMethodQueryResults from '../utils/mapMethodQueryResults'

const mockBreadcrumbs = [{ title: 'UX Strategy Kit', href: '/' }]

const Category: React.FC<PageProps<
  Queries.CategoryPageQuery,
  Queries.CategoryPageQueryVariables
>> = ({ data, pageContext: { slug: name } }) => {
  const [filters, setFilters] = useState({})
  const [methods, setMethods] = useState(mapMethodQueryResults(data))

  useEffect(() => {
    setMethods(filterMethods(mapMethodQueryResults(data), filters))
  }, [filters, setMethods, data])
  return (
    <DefaultLayout
      className="page-category"
      hasBanner={false}
      seo={{ title: capitalize(name) }}
    >
      <Section className="breadcrumbs-section">
        <Breadcrumbs
          breadcrumbs={[
            ...mockBreadcrumbs,
            { title: capitalize(name), url: '#' },
          ]}
        />
      </Section>
      <Section className="tools-section">
        <header className="section-header section-header--flex">
          <SectionHeadline className="section-header__headline">
            {name}
          </SectionHeadline>
          {data.directus.category[0].description && (
            <div
              className="section-header__subline"
              dangerouslySetInnerHTML={{
                __html: data.directus.category[0].description,
              }}
            />
          )}
        </header>

        <ToolsListing
          filters={
            <FilterBar
              found={methods.length}
              workflowTags={[]}
              onUpdate={setFilters}
              canSort={false}
            />
          }
          methods={methods}
        />
      </Section>
    </DefaultLayout>
  )
}

export default Category

export const query = graphql`
  query CategoryPage($slug: [String]) {
    directus {
      category(filter: { slug: { _in: $slug } }) {
        description
      }
      method(
        filter: { categories: { category_id: { slug: { _in: $slug } } } }
        limit: -1
      ) {
        name
        logo {
          id
          imageFile {
            publicURL
          }
        }
        headline
        categories {
          category_id {
            slug
          }
        }
        tags
        slug
        participants_from
        participants_to
        duration_from
        duration_to
        gallery {
          directus_files_id {
            id
            imageFile {
              publicURL
            }
          }
        }
        difficulty
      }
    }
  }
`
